import React from "react"
import { graphql } from "gatsby"
import Gallery from "react-photo-gallery"
import Slider from "react-slick"

import IndexLayout from '../layouts'
import useWindowSize from "../styles/useWindowSize"




const GalleryTemplatePage = ({ data }) => {
  const node = data.allStrapiGalerie.nodes[0]

  const [currentImage, setCurrentImage] = React.useState(0)
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false)
  const [width, height] = useWindowSize()
  const sliderRef = React.useRef(null)

  const openLightbox = React.useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    sliderRef.current.slickGoTo(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    sliderRef.current.slickGoTo(0)
    setViewerIsOpen(false)
  }

  return (
    <IndexLayout>
      <div className="hero is-primary">
        <div className="hero-body">
          <div className="container ">
            <h1 className="title has-text-centered">{node.Titre}</h1>
          </div>
        </div>
      </div>
      <div className="section">
        <Gallery
          onClick={openLightbox}
          direction={"column"}
          photos={node.Photographies.map(
            (photo) => {
              return {
                src: photo.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
                width: photo.localFile.childImageSharp.resize.aspectRatio, key: photo.id,
                height: 1
              }
            }
          )}
        ></Gallery>
      </div>
      <div className={viewerIsOpen ? "modal is-active" : "modal"}>
        <div className="modal-background">
          <Slider initialSlide={currentImage} ref={sliderRef}>
            {node.Photographies.map((photo) => (
              <div>
                <img
                  key={photo.id}
                  style={{
                    height: `${height}px`,
                    width: `${width}px`,
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                  src={photo.localFile.childImageSharp.gatsbyImageData.images.fallback.src}
                />
              </div>
            ))}
          </Slider>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={closeLightbox}
        />
      </div>
    </IndexLayout>
  )
}

export default GalleryTemplatePage

export const pageQuery = graphql`
  query galerieQuery($id: String) {
    allStrapiGalerie(filter: {id: {eq: $id}}) {
      nodes {
        Slug
        id
        Titre
        Photographies {
          localFile {
            childImageSharp {
              gatsbyImageData
              resize {
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`
